import React from "react";

function GoodCare(){



    return(
        <>
        <div className="goodCare_banner">
        <span style={{fontSize:'clamp(22px, 2.8vw, 38px)', fontWeight:'bold'}}>Focusing on Quality Treatment in the Cobb, Cherokee, Dekalb, and Fulton Counties</span>
        </div>
        </>
    )
}

export default GoodCare