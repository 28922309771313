import React from "react";
import Banner1 from "./Banner1";
import InfoSection from "./InfoSection";
import SubmitForm from "./SubmitForm";





function Locations(){
    let info = [
        {title:`Marietta, GA`, address:`3208 Canton Rd. Suite 112 Marietta, GA 30066`, phone:`(770) 800-2070`, photo:`https://lh3.googleusercontent.com/p/AF1QipMF0jhUKB_qhYRYqX7nWh2aTIFwlVULv7EukGTd=s680-w680-h510`},
        {title:`Pro Healthcare Tucker`, address:`1918 Northlake Parkway Suite 102 Tucker, GA 30084`, phone:`(770) 800-2070`, photo:``},
        {title:'Dalton, GA', address:`1423 West Walnut Avenue, Suite 3
        Dalton, GA 30720`, phone:"706-229-3993", photo:"https://totalhealthchiro.com/wp-content/uploads/2019/03/Daltons-rotated.jpg"},
        {title:`East Ridge, Chattanooga`, address:`400 South Moore Road Suite B
        Chattanooga, TN 37412`, phone:`423-894-4771`, photo:`https://totalhealthchiro.com/wp-content/uploads/2019/03/Total-health-East-Ridge.jpg`},
        {title:`Fort Oglethorpe, GA`, address:`955 Battlefield Parkway
        Fort Oglethorpe, GA 30742`, phone:`706-866-7575`, photo:`https://totalhealthchiro.com/wp-content/uploads/2019/03/fort-oglethorpe-ga-1.jpg`},
        {title:`Hixson/Soddy-Daisy, TN`, address:`5617 Tennessee 153 Suite 104
        Hixson, TN 37343`, phone:`423-875-8786`, photo:`https://totalhealthchiro.com/wp-content/uploads/2019/03/Hixson-Soddy-Daisy-TN.jpeg`},
        {title:`Morrow, GA`, address:`1515 Morrow Rd
        Morrow, GA 30260`, phone:`770-731-1007`, photo:`https://totalhealthchiro.com/wp-content/uploads/2019/04/Morrow-1152x1536.jpg`},
        {title:`Shallowford/Hwy 58, Chattanooga`, address:`2372 Lifestyle Way #174
        Chattanooga, TN 37421`, phone:`423-899-0309`, photo:`https://totalhealthchiro.com/wp-content/uploads/2019/03/Shallowford.jpg`},
        {title:`St. Elmo, Chattanooga`, address:`3742 Tennessee Avenue Ste. 104
        Chattanooga, TN 37409`, phone:`423-602-5656`, photo:`https://totalhealthchiro.com/wp-content/uploads/2019/03/St.Elmo_.jpg`},
    ]
    
//     let photo = 'https://media.fshoq.com/images/207/woman-measuring-the-pressure-at-the-doctor-207-small.jpg'
    

let photo = 'https://images.unsplash.com/photo-1619468129361-605ebea04b44?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80';

   return(
    <>
     <Banner1 text1={'Locations'} image={photo}/>
     <div className="info_section_holder">
       <div className="info_section">
        <div className="info1">
        {/* {info.map(x =>  x.title1 ? <h1>{x.title1}</h1> : <h2>{x.title2}</h2>)} */}
        {info.map(x =>   <>
            <div className="location_cell">
                <div>
                    {x.title === `Marietta, GA` || x.title === `Pro Healthcare Tucker` ? x.photo ? <img src={x.photo} alt=""/> : <img style={{visibility:'hidden'}} src={x.photo} alt=""/> : ""}
                </div>
                <div>
                    <h2>{x.title}</h2>
                    <span className="l_cell_info"><a href={`https://www.google.com/maps/search/${x.address}`}>{x.address}</a></span><br /><br />
                    <span className="l_cell_info"><a href={`tel:${x.phone}`}>{x.phone}</a></span>
                    

                </div>
                {/* <hr /> */}
            </div>
        </>
// typeof(x.text[0]) === 'object' ? <ul>{x.text.map(y => <li>{y}</li>)}</ul> : x.text.map(z => <p>{z}</p>)
            )}
        
        
        
        
        
        {/* {info.map(x => typeof(x.text[0]) === 'object' ? <ul>{x.text.map(y => <li>{y}</li>)}</ul> : x.text.map(z => <p>{z}</p>) )} */}
        {info.map(x => console.log(x.text))}
        </div>
        <div className="form1">
            <SubmitForm />
        </div>
            
            </div>
       </div>
    </>
   )
}



export default Locations